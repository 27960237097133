import './styles.css'

function CenteredCard({children}) {
  return (
    <div className='divParent'>
      <span>{children}</span>
    </div>
  );
}

export default CenteredCard;
