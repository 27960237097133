import "./App.css";

import CenteredCard from "./components/CenteredCard";

function App() {
  
  return (
    <div className="App">
      <div className="App-header">
        <div className="cardsContainer">
          <CenteredCard><div>Só uma children qualquer.</div> </CenteredCard>
          <CenteredCard><div>Só uma outra children qualquer.</div> </CenteredCard>
          <CenteredCard><div>Só uma children qualquer também</div> </CenteredCard>
        </div>
      </div>
    </div>
  );
}

export default App;

